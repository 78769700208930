.agm_section {
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    min-height: 100vh;
    padding-top: 100px;
    padding-bottom: 35px;
    position: relative;
}
.wrapper{
    font-family: tabloid;
    position: absolute;
    right: -31%;
    top: 31%;
    transform: translateY(-50%);
}
.wrapper_2{
    position: unset;
}
.heading{
    font-weight: 500;
    font-size: 146px;
    text-transform: uppercase;
    color: #FFFFFF;
    line-height: 118px;
    margin-top:100px
}
.img_container.social-icon_link{
    background-color: unset;
    border: 2px solid white;
}
.heading_2{
    font-weight: 500;
    font-size: 151px;
    text-transform: uppercase;
    color: #FFFFFF;
    line-height: 155px;
    margin-top: 100px
}
.pera_text{
    font-weight: 400;
    font-size: 14px;
    letter-spacing: -0.36px;
    text-transform: uppercase;
    color: #FFFFFF;
}
.other_projects{
    position: absolute;
    right: 0%;
    top: 13%;
    background: #E8C333;
    letter-spacing: -0.36px;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 400;
    color: #000000;
    padding: 0 8px;
    z-index: 8;
    font-family: "Anton";
    padding: 8px 30px 8px 18px;
}
.other_projects img{
    margin-left: 13px;
}
.agm_section ul li{
    font-weight: 400;
    font-size: 18px;
    letter-spacing: -0.36px;
    color: #FFFFFF;      
    font-family: 'Poppins', sans-serif;

}
.services_description  p{
    font-size: 24px;
    line-height: 42px;
}
.intro{
    background-color: #1E1E1E;
}
.body{
    cursor: crosshair;
  }
.rounded{
width: 26px;
height: 26px;
border: 2px solid #3d3b3b;
border-radius: 50%!important;
background-color: unset;
position: fixed;
}
.introduction_section {
    margin-top: 170px;
    font-size: 20px;
    color: white;
    text-align: center;
    margin-bottom: 203px;
}
.introduction_section p{
    font-size: 40px;
    line-height: 38px;
}
.nav-link.active{
    border-bottom: 1px solid white!important;
}
.introduction_section p span{
     color:#E8C333;
}
.services_experience_section{
    background-color: #1E1E1E;
    color: #FFFFFF;
    /* padding-top:190px; */
}

.services {
    font-weight: 600;
    font-size: 70px;
    line-height: 6px;
    text-transform: uppercase;
    font-family: 'Poppins';
    margin-top: 35px;
}

.services_description h2{
    font-weight: 500;
    font-size: 18px;
    letter-spacing: -0.36px;
    text-transform: uppercase;
    color: #FFFFFF;
}
.services_description ul li{
    font-weight: 400;
    font-size: 18px;
    letter-spacing: -0.36px;
    color: #FFFFFF;
}
.awards_achievement_section{
    background-color: #1E1E1E;
    color: #FFFFFF;
    padding-top:170px;
}
.awards_achievement_section h2{
    font-weight: 500;
    font-size: 70px;
    text-transform: uppercase;
    color: #FFFFFF;
}
.border-style {
   border-top:1px solid white;
}
.data_cntainer h2{
    font-weight: 500;
    font-size: 80px;
    text-transform: uppercase;
    color: #FFFFFF;
    line-height: 80px;
}
.drop-email-section label{
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color:white;
    letter-spacing: -0.36px;
    text-transform: uppercase;

}
.drop-email-section p{
    font-size: 45px;
    font-weight: 400;
    line-height: 55px;
    letter-spacing: -0.36px;
    text-transform: uppercase;
}
/* .awards_para{
    margin-left: 200px;
} */

.fa-long-arrow-right {
    font-size: 25px;
}

.lets-make-something{
    margin-top: 206px;
}

.lets-make-image{
    background-size: cover;
    background-repeat: no-repeat;
    height: 600px;
    background-position: center center;
}
a:hover{
    color: white !important;
  }
  .drop-email-section {
    margin-top: 30px;
}
@media screen and (max-width:996px) {
.agm_section {
    background-position: center;
    /* padding-bottom: 0; */
}
.services_experience_section{
    margin-top:-5px;
    padding:0 20px;
}
.awards_achievement_section{
    margin-top:-5px;
    padding: 85px 20px 0;
}
.services{
    margin-top: 152px;
}
.introduction_section {
    margin-top: 74px;
    margin-bottom: 35px;
}
.mystory{
    margin-bottom: 70px;
}
.lets-make-something{
    margin-top: 70px;
}
.lets-make-image {
    margin-top: 0px;
    margin-bottom: 100px;
}
.drop-email-section {
    margin-top: 15px;
}
}
@media screen and (max-width:767px) {
    .agm_section ul{
      padding-left: 20px;
    }
    .drop-email-section p {
        font-size: 17px;
    }
    .services{
       font-size: 60px;
       margin-top:107px;
    }
    .lets-make-image {
        /* min-height: 271px; */
        width: 92%;
        margin: 40px auto;
    }
    .mystory {
        margin-bottom: 45px;
        font-size: 40px;
        line-height: 40px;
    }
    .awards_achievement_section h2 {
        font-size: 40px;
        line-height: 40px;
    }
    .awards_achievement_section {
        padding-top: 85px;
    }
    .introduction_section {
       text-align: left;
    }
    .introduction_section p{
        padding: 0 38px 0 20px!important;
    }
    .other_projects{
        right:0%
    }
    .agm_section {
        background-position: right;
    }
    .heading{
        margin-top: 215px;
        font-size: 70px;
        line-height: 64px;
    }
}
@media screen and (max-width:767px) {
    .heading{
        font-size: 45px;
        line-height: 45px;
    }
    .agm_section {
        background-position: 42% 50%;
    }
    .wrapper {
        right: 8%;
        bottom: -58%;
        transform: translateY(-600%);
    }
    .heading_2 {
        font-size: 110px;
        line-height: 112px;
    }
}
@media screen and (max-width:596px) {
    .wrapper {
        transform: translateY(-360%);
    }
    .heading_2{
        font-size: 65px;
        line-height: 78px;
    }
}
.drop-me-line{
    margin-top:100px;
    font-size: 15px;
    text-transform: uppercase;
}

.drop-email{
    font-family: revert;
    font-style: normal;
    font-weight: 500;
    font-size: 45px;
    line-height: 55px;
}

.download_line , .contact_line , .linkdin_line , .twitter_line , .story_line , .mystory , .service_exp{
    width:0%;
    background-color: white;
    height:3px;
}
  .linking:hover + .contact_line , .linking:hover + .linkdin_line ,.linking:hover + .twitter_line {
       animation: routelink 1.5s linear;
}
h2.exp_link:hover + .service_exp {
    animation: servicelink 1.5s linear;
}
.linking:hover + .story_line{
    animation: storylink 1.5s linear;
}
h2.services:hover + .mystory{
    animation: mystory 1.5s linear; 
    cursor: pointer; 
    margin-top:27px;
}
@keyframes servicelink{
    from{
        width:0%
    }
    to{
        width:58%
    }
}
@keyframes mystory{
    from{
        width:0%
    }
    to{
        width:58%
    }
}
@keyframes  storylink{
    from{
        width:0%
    }
    to{
        width:23%
    }
}
@keyframes routelink{
    from{
        width:0%
    }
    to{
        width:41%
    }
}
.linking:hover + .download_line{
    animation: downloadroute 1.5s linear;
}
@keyframes downloadroute{
    from{
        width:0%
    }
    to{
        width:84%
    }
}

/* animation */

.drop-main {
    opacity: 0;
    overflow:hidden;
    animation:drop 1.0s cubic-bezier(0, 0, 0.79, 0.23) forwards;
    margin-top: 0px!important;
    padding-top: 0px!important;
    /* animation: drop .9s linear forwards; */
}
@keyframes drop {
    0% {
        transform: translate(-10px , 100px);
        opacity: 0;
    }
    50% {
        transform: translateY(-7px,110px);
        opacity: 0;
    }
    65% {
        transform: translateY(-3px,120px);
        opacity: 0.6;
    }
    75% {
        transform: translateY(0px);
        opacity: 0.7;
    }
    100% {
        transform: translateY(0px);
        opacity: 1;
    }
}

.drop-main:nth-child(1){
    animation-delay: 0.1s;
}
.drop-main:nth-child(2){
    animation-delay: 0.2s;
}
.drop-main:nth-child(3){
    animation-delay: 0.3s;
}
.drop-main:nth-child(4){
    animation-delay: 0.4s;
}
.drop-main:nth-child(5){
    animation-delay: 0.5s;
}
.drop-main:nth-child(6){
    animation-delay: 0.6s;
}
.drop-main:nth-child(7){
    animation-delay: 0.7s;
}
.drop-main:nth-child(8){
    animation-delay: 0.8s;
}