.footer_section{
    background-color: #ECECEC;
}
.footer_wrapper {
    padding: 64px 77px 30px;
}
.footer_wrapper h2{
    font-weight: 400;
    font-size: 48px;
    line-height: 61px;
    color: #222222;
}
.footer_wrapper button{
    font-weight: 400;
    font-size: 14px;
    border: 1px solid #222222;
    color: #222222;
    height: 41px;
    display: flex;
    align-items: center;
    margin-left: 13px;
    padding: 0 25px;
    border-radius: 38px;
}
ul.got_a_project {
    list-style: none;
    display: flex;
    justify-content: space-between;
    margin-top:67px;
    margin-bottom: 60px;
}
ul.got_a_project li{
    border-top: 1px solid #545454;
    width: 29%;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}
ul.got_a_project li p{
    font-size: 20px;
    font-weight: 600;
    color: #222222;
    padding-top: 13px;
    font-family:"EB Garamond"
}
ul.got_a_project li p span{
    font-weight: 400;
    font-size: 16px;
    color: #6B6B6B;
    margin-top:5px;
}
.footer_section span.img_container{
    height: 32px;
    width: 32px;
    border-radius: 32px;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
}
@media screen and (max-width:996px) {
ul.got_a_project li {
    width: 31%;
}
.footer_wrapper {
    padding: 58px 20px 58px;
}
}
@media screen and (max-width:767px) {
    ul.got_a_project {
        flex-wrap: wrap;
        padding-left: 0;
    }
    ul.got_a_project li {
        width: 100%;
    }
    .footer_wrapper h2 {
        font-size: 33px;
        line-height: 40px;
    }
    .footer_wrapper button {
        font-size: 11px;
        height: 30px;
        padding: 0 8px;
    }
    .footer_wrapper button img{
        height: 12px;
        width:12px;
        object-fit: contain;
    }
}