/* accordian */
.OurServices_section.our_process_section {
    background-color: white;
    padding: 170px 0;
}

.OurServices_section.our_process_section h2.title_ecom {
    font-size: 177px;
}
.OurServices_section.our_process_section .accordion-button::after {
    position: absolute;
    top: 24%;
    right: 0%;
}
.slider_section {
    padding: 97px 0 113px;
    background-color: #ECECEC;
}
.project_values{
  padding: 0 69px;
}
.home_page_section .slider_section .values_text {
    font-size: 177px;
    font-weight: 500;
    margin-top: 23px;
    text-transform: uppercase;
    color: #222222;
}
.slider_content{
    padding: 10px 0 100px;
}
.home_page_section .slider_section .slider_content h2 {
    font-weight: 500;
    font-size: 25px;
    text-transform: uppercase;
    color: #202020;
    margin-top: 31px;
    border-top: 9px solid black;
    width: 142px;
    padding-top: 2px;
    margin-left: 57px;
}
.body{
  cursor: crosshair;
}
.rounded{
  pointer-events: none;
  width: 26px;
  height: 26px;
  border: 2px solid #3d3b3b;
  border-radius: 50%!important;
  background-color: unset;
  position: fixed;
}
.home_page_section .slider_section p {
    font-weight: 400;
    font-size: 16px;
    color: #2D2D2D;
    margin-top: 6px;
    margin-left: 57px;
}
.home_page_section .slider_section .slick-dots {
    position: absolute;
    bottom: 0px;
}
.home_page_section .slider_section .slick-dots li {
    margin: 0 0px;
    padding: 0;
}
.home_page_section .slider_section .slick-dots li button:before {
    font-family: 'slick';
    font-size: 12px;
}
.slick-prev, .slick-next{
    display: none!important;
}
.project_section{
    margin: 60px 0 30px;
    height:100%;
}
.project_title{
    font-size: 177px;
    font-weight: 400;
    margin-top: 23px;
    text-transform: uppercase;
    color: #222222;
    border-bottom: 1px solid black;
}
.submenu ul li{
    font-weight: 700;
    font-size: 14px;
    text-transform: uppercase;
    color: #000000;

}
.projects_img_content {
    margin-top: 40px;
}
.projects_img_content h2{
    font-weight: 500;
    font-size: 25px;
    text-transform: uppercase;
    color: #202020;
    padding-top: 3px;
}
.projects_img_content h3{
    font-weight: 500;
    font-size: 14px;
    text-transform: uppercase;
    color: #000000;
    margin-top: 2px;
}
.projects_img_content p{
    font-weight: 400;
    font-size: 16px;
    color: #2D2D2D;
}
.values_line , .process_line{
  width:0%;
  background-color: black;
  height:3px;
}
.values_text:hover .values_line{
  animation: linewidth 1.5s linear;
}
.title_ecom:hover + .process_line{
  animation: processwidth 1.5s linear;
}
.process_line{
  margin-top:39px;
}
@keyframes linewidth {
from{
   width:0%;
}
to{
   width:48%
}
}
@keyframes  processwidth {
  from{
     width:0%;
  }
  to{
     width:38%
  }
  }
/* brand_section */
.your_brand_section{
  background-image: url('/src/images/Bg_hero.gif');
  
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
    
}
.your_brand_section .brand_text{
   
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    color: white;
}
.your_brand_section .brandtitle{
    font-weight: 700;
    font-size: 90px;
    line-height: 90px;
    text-transform: uppercase;
   
    color: #FFFFFF;
    margin-top: 25px;
   
}
.yellow_text{
    color: #FFE03F;
}
.your_brand_section  img{
    display: block;
    margin: 90px auto 0px;
}
.loadmore_img{
    height: 29px;
    width: 29px;
    margin-left: 8px;
    object-fit: contain;
}
.load_more_brn{
    font-weight: 400;
    font-size: 19px;
    text-transform: uppercase;
    color: #202020
}
@media screen and (max-width: 996px) {
  .OurServices_section.our_process_section{
    padding: 58px 11px;
  }
}
@media screen and (max-width: 767px) {
.OurServices_section.our_process_section .accordion-button::after {
    top: 16%;
}
.your_brand_section .brand_text {
    font-size: 16px;
}
.home_page_section .slider_section .slider_content h2{
  text-align: center;
  width: 230px;
}
.your_brand_section .brandtitle {
    font-size: 48px;
    line-height: 54px;
}
.your_brand_section{
    background-color: #222222!important;
}
.project_title {
    font-size: 70px;
}
.project_section{
  margin: 73px 20px 30px;
}
.home_page_section .slider_section .values_text{
    font-size: 70px;
}
.OurServices_section.our_process_section h2.title_ecom{
    font-size: 70px;
}
.slider_section{
    padding: 71px 20px 100px;
}
.dotted_icon{
    display: none;
}
.submenu{
    width:100%
}
.submenu ul{
    width:100%;
    justify-content: space-between;
}
.home_page_section .slider_section .slider_content h2 {
    margin:31px auto 10px;
}
.home_page_section .slider_section .slider_content p {
    margin:6px auto;
    text-align: center;
}
.home_page_section .slider_section .slick-dots {
    bottom: -53px;
}
}

@keyframes gradient {
	3% {
		 background: conic-gradient(from 10deg at 50% 50%,#020c12 0deg,#63b7ec 0deg,#525759 .04deg,#222 360deg);
	}
	6% {
		background: conic-gradient(from 20deg at 50% 50%,#020c12 0deg,#63b7ec 0deg,#525759 .04deg,#222 360deg);
	}
	9% {
		background: conic-gradient(from 30deg at 50% 50%,#020c12 0deg,#63b7ec 0deg,#525759 .04deg,#222 360deg);
	}
   12% {
		background: conic-gradient(from 50deg at 50% 50%,#020c12 0deg,#63b7ec 0deg,#525759 .04deg,#222 360deg);
	}
   15% {
		background: conic-gradient(from 60deg at 50% 50%,#020c12 0deg,#63b7ec 0deg,#525759 .04deg,#222 360deg);
	}
  18% {
		background: conic-gradient(from 70deg at 50% 50%,#020c12 0deg,#63b7ec 0deg,#525759 .04deg,#222 360deg);
	}
  21% {
		background: conic-gradient(from 80deg at 50% 50%,#020c12 0deg,#63b7ec 0deg,#525759 .04deg,#222 360deg);
	}
  24% {
		background: conic-gradient(from 90deg at 50% 50%,#020c12 0deg,#63b7ec 0deg,#525759 .04deg,#222 360deg);
	}
  27% {
		background: conic-gradient(from 100deg at 50% 50%,#020c12 0deg,#63b7ec 0deg,#525759 .04deg,#222 360deg);
	}
  30% {
		background: conic-gradient(from 110deg at 50% 50%,#020c12 0deg,#63b7ec 0deg,#525759 .04deg,#222 360deg);
	}
  33%{
    background: conic-gradient(from 120deg at 50% 50%,#020c12 0deg,#63b7ec 0deg,#525759 .04deg,#222 360deg);
  }
  36%{
    background: conic-gradient(from 140deg at 50% 50%,#020c12 0deg,#63b7ec 0deg,#525759 .04deg,#222 360deg);
  }
  39%{
    background: conic-gradient(from 150deg at 50% 50%,#020c12 0deg,#63b7ec 0deg,#525759 .04deg,#222 360deg);
  }
  42%{
    background: conic-gradient(from 160deg at 50% 50%,#020c12 0deg,#63b7ec 0deg,#525759 .04deg,#222 360deg);
  }
  45%{
    background: conic-gradient(from 170deg at 50% 50%,#020c12 0deg,#63b7ec 0deg,#525759 .04deg,#222 360deg);
  }
  48%{
    background: conic-gradient(from 190deg at 50% 50%,#020c12 0deg,#63b7ec 0deg,#525759 .04deg,#222 360deg);
  }
  51%{
    background: conic-gradient(from 200deg at 50% 50%,#020c12 0deg,#63b7ec 0deg,#525759 .04deg,#222 360deg);
  }
  54%{
    background: conic-gradient(from 210deg at 50% 50%,#020c12 0deg,#63b7ec 0deg,#525759 .04deg,#222 360deg);
  }
  60%{
    background: conic-gradient(from 220deg at 50% 50%,#020c12 0deg,#63b7ec 0deg,#525759 .04deg,#222 360deg);
  }
  63%{
    background: conic-gradient(from 230deg at 50% 50%,#020c12 0deg,#63b7ec 0deg,#525759 .04deg,#222 360deg);
  }
  66%{
    background: conic-gradient(from 250deg at 50% 50%,#020c12 0deg,#63b7ec 0deg,#525759 .04deg,#222 360deg);
  }
  69%{
    background: conic-gradient(from 260deg at 50% 50%,#020c12 0deg,#63b7ec 0deg,#525759 .04deg,#222 360deg);
  }
  72%{
    background: conic-gradient(from 270deg at 50% 50%,#020c12 0deg,#63b7ec 0deg,#525759 .04deg,#222 360deg);
  }
  75%{
    background: conic-gradient(from 280deg at 50% 50%,#020c12 0deg,#63b7ec 0deg,#525759 .04deg,#222 360deg);
  }
  80%{
    background: conic-gradient(from 290deg at 50% 50%,#020c12 0deg,#63b7ec 0deg,#525759 .04deg,#222 360deg);
  }
  83%{
    background: conic-gradient(from 300deg at 50% 50%,#020c12 0deg,#63b7ec 0deg,#525759 .04deg,#222 360deg);
  }
  86%{
    background: conic-gradient(from 320deg at 50% 50%,#020c12 0deg,#63b7ec 0deg,#525759 .04deg,#222 360deg);
  }
  89%{
    background: conic-gradient(from 330deg at 50% 50%,#020c12 0deg,#63b7ec 0deg,#525759 .04deg,#222 360deg);
  }
  94%{
    background: conic-gradient(from 340deg at 50% 50%,#020c12 0deg,#63b7ec 0deg,#525759 .04deg,#222 360deg);
  }
  97%{
    background: conic-gradient(from 350deg at 50% 50%,#020c12 0deg,#63b7ec 0deg,#525759 .04deg,#222 360deg);
  }
  100%{
    background: conic-gradient(from 360deg at 50% 50%,#020c12 0deg,#63b7ec 0deg,#525759 .04deg,#222 360deg);
  }
}

