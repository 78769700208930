.header-heading {
    margin: 0;
    font-weight: 500;
    font-size: 16px;
    color: #ffffff;
    line-height: 24px;
}
.right_arrow{
    margin-top: 5px;
    margin-left: 5px
}
.header-heading-link {
    margin: 10px;
    font-weight: 500;
    font-size: 20px;
    color: white;
    line-height: 24px;
    font-family: "tabloid"; 
    margin:0;
}
.line_animate , .line_animatetwo , .line_animatethree{
    width:0%;
    background-color: white;
    height:3px;
}
.header-heading-link:hover + .line_animate , .header-heading-link:hover + .line_animatetwo ,.header-heading-link:hover + .line_animatethree{
       animation: navlinewidth 1.5s linear;
}
@keyframes navlinewidth {
    from{
        width:0%;
    }
    to{
        width:100%
    }
}
.logo-container {
    height: 40px;
    width: 40px;
    background-color: #ffffff;
    border-radius: 50%;
}

.user-icon-box img {
    width: 36px;
    height: 26px;
    object-fit: contain;
}

.header.stickyHeader {
    position: fixed;
    line-height: 53px;
    height: 80px;
    width: 100%;
    padding: 0;
    margin: auto;
    text-align: left;
    z-index: 8;
    background-color: transparent;
    top: 0 !important;
}

.header.stickyHeader.bgcolor{
    background-color: #000000;
}
.lets_grow{
    font-size: 32px;
    text-transform: uppercase;
}
a.lets-grow-link:hover{
    color: black!important;
}
.dropend .btn-success {
    background: none !important;
    border: none !important;
    font-size: 40px;
    font-weight: 700;
    line-height: 61px;
    list-style-type: none;
    padding-top: 2px;
    color:  #ffe03f;
    padding: 0;
    text-transform: uppercase;
    display: flex;
    align-items:center;
}
.dropend .btn-success:hover{
    color:#4B9DD1;
}
.list-item:hover{
    color:#4B9DD1;
}
.clicked{
    color:#4B9DD1!important;
}
.dropdown_list {
    font-weight: 500;
    font-size: 24px;
    color:  #ffe03f;
    padding: 0px 16px
}
.dropdown_list:hover a{
    color:  #4B9DD1!important;
}
.dropdown_list:hover{
    color: #4B9DD1;
    background-color: none;
}
.dropdown-item:focus, .dropdown-item:hover {
    background-color: unset!important;
}
.dropdown-menu.show {
    border: 0;
    border-radius: 0;
    padding: 0;
    background: none;
}
.social_icon_container{
    border: 2px solid #000000;
    padding: 3px 9px;
    border-radius: 50%;
    color: #000000;
    margin-right: 30px;
    font-size: 22px;
}
.fa-twitter:hover{
    border-width: thin;
    font-size: 25px;
}
.fa-linkedin:hover{
    border-width: thin;
    font-size: 25px;
}
.lets_grow a{
    font-weight: 700;
    font-size: 32px;
    color: #000000;
    font-family: 'poppins', sans-serif;
}
.lets_grow a:hover{
    color:black!important;
}
.social_icon{
    width: 100%;
    left: 0;
    bottom: 9%;
    padding: 0 184px;
    position: inherit;
    padding: inherit;
}
@media screen and (max-width:767px) {
    .user-icon-box img {
        width: 24px
    }
    .header.stickyHeader{
        padding: 0 20px!important;
    }
    .list-item {
      font-size: 27px!important;
      line-height: 47px!important;
  }
  .lets_grow a {
    font-size: 19px;
}
  .dropend .btn-success {
    font-size: 27px;
  }
  .dropdown_list {
    font-size: 12px;
    line-height: 47px!important;
}
.menu {
  font-size: 17px;
}
.social_icon_container {
    padding: 1px 6px;
    font-size: 20px;
}
}

.user-icon-box img {
    width: 36px;
    height: 26px;
    object-fit: contain;
}
.side-offcanvas {
    background-color: #FFFFFF;
    width: 100% !important;
    border-right: none!important;
    box-shadow: 0 0 0 rgba(0,0,0,0.2);
  animation: shadow-box 1s infinite linear;
}
@keyframes shadow-box {
    0% { box-shadow: 0 0 0 rgba(0,0,0,0.2); }
    50% { box-shadow: 0 0 20px rgba(0,0,0,0.2); }
    100% { box-shadow: 0 0 0 rgba(0,0,0,0.2); }
  }
.list-item {
    font-size: 48px;
    font-weight: 400;
    line-height: 68px;
    list-style-type: none;
    padding-top: 2px;
    color: #ffe03f;
    text-transform: uppercase;
    font-family: "tabloid";
}

.menu {
    list-style-type: none;
    color: #000000;
    font-weight: 700;
    font-size: 24px;
}

button.hemburger {
    background: transparent;
    border: 0;
    outline: none;
}

.btn-close{
    background-image: url("../../assets/images/close.png");
}

button.hemburger.btn:hover {
    background-color: unset !important;
    border: none !important;
}

@media only screen and (max-width: 556px) {
    .social_icon{
        padding:0 0px;
    }
}

.dropdown-toggle::after {
    border-top: unset !important;
    border-right: 0 !important;
    border-bottom: unset !important;
    border-left: unset !important;
    position: absolute !important;
    top: 0% !important;
    background-image: url("../../assets/images/rightarrow.svg");
}

.dropdown-menu.show{
    position: absolute !important;
    top: 0% !important;
}