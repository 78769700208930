.marketing_page {
    background-color: #ECECEC;
    padding-top: 170px;
    height: 700px;
}

.marketing_page h2 {
    font-weight: 500;
    font-size: 146px;
    line-height: 118px;
    text-transform: uppercase;
    color: #2D2D2D;
    letter-spacing: 0;
    margin-bottom: 30px;
}
.cut_text{
    color:#FFE03F;
}
.marketing_section .header-heading-link{
    color:black;
}
.marketing_section .bgcolor .header-heading-link{
    color:white;
}
.marketing_section .bgcolor .line_animate , .marketing_section .bgcolor .line_animatetwo , .marketing_section .bgcolor .line_animatethree{
    background-color: white;
}
.marketing_section .line_animate , .marketing_section .line_animatetwo , .marketing_section .line_animatethree{
    background-color: black;
}
.marketing_page h2 span {
    color: #4B9DD1;
}

.marketing_page h2 label {
    color: #FFE03F;
}

.marketing_page p {
    font-weight: 400;
    font-size: 20px;
    color: #222222;
    line-height: 26px;
}
.vehicle_conatiner img:hover{
    transform: scale(1.1);
    transition: 1s ease-in;
}
.vehicle_conatiner img{
    transition: scale(0);
    transition: 1s ease-in;
}
.marketing_page .explore_btn {
    color: #4B9DD1;
}
.marketing_page .explore_btn img{
    height:92px;
    width:92px;
    object-fit: contain;
}
.sale_section {
    padding: 343px 0 326px
}
.yellow_color_display{
    color:#4B9DD1;
}
.blue_color_display{
    color:#FFE03F;
}
.sale_section h2 {
    font-weight: 500;
    font-size: 32px;
    color: black;
    text-transform: uppercase;
    letter-spacing: -0.36px;
}
.sale_section p {
    font-weight: 400;
    font-size: 23px;
    color: #2D2D2D
}
.OurServices_section{
    background-color: #ECECEC;
    padding: 290px 0 191px;
}
.OurServices_section .title_ecom {
    font-weight: 500;
    font-size: 70px;
    line-height: 70px;
    margin-top: 23px;
    text-transform: uppercase;
    color: #222222;
}
.OurServices_section p {
    font-weight: 400;
    font-size: 20px;
    letter-spacing: -0.36px;
    color: #222222;
    line-height: 33px;
}
.collapse {
    visibility: visible;
}
.OurServices_section .accordion-button::after{
    background-image:url("../../assets/images/add_icon.png");
}
.OurServices_section .accordion-button:not(.collapsed)::after {
    background-image: url("../../assets/images/minus.png");
}
/* .OurServices_section .accordion-button{
    background-color: #ECECEC!important;
} */
/* .OurServices_section .accordion-item:first-of-type .accordion-button{
    border-radius: 0;
    padding: 25px 15px;
} */
.OurServices_section .accordion-item-start .accordion-button{
    padding: 25px 15px;
}
.OurServices_section .accordion-button:focus{
    box-shadow: none;
    border-color: none;
}
.OurServices_section .accordion-item-start{
    background-color: unset;
    border-top:1px solid #202020;
    border-bottom:none;
    border-left: none;
    border-right: none;
    margin-bottom: 25px;
    padding-top: 25px;
}
/* .OurServices_section .accordion-item:first-of-type{
    border-radius: 0;
} */
.accordion-button:not(.collapsed){
    background-color: unset;
}
.accordian_section h2{
     font-size: 25px;
     font-weight: 500;
     text-transform: uppercase;
     color: #202020
}
.accordian_section p{
    font-weight: 400;
    font-size: 18px;
    color: #202020
}
.cut_through_section h2 {
    font-weight: 500;
    font-size: 70px;
    line-height: 63px;
    text-transform: uppercase;
    color: #222222;
}
.cut_through_section p {
    font-weight: 400;
    font-size: 23px;
    letter-spacing: -0.36px;
    color: #2D2D2D;
    line-height: 33px;
}
.cut_through_section{
    padding: 120px 0 130px;
}
.effactive_digital_section{
    padding: 170px 0;
    background-color: #ECECEC;
}
.effactive_digital_section h2{
    font-weight: 400;
    font-size: 20px;
    line-height: 35px;
    color: #2D2D2D;
    text-align: center;
}
.effactive_digital_section span{
    color: #202020;
    font-size: 70px;
}
.content_effact{
    padding: 66px 0px 0px
}
.content_effact img{
        height: 200px;
        width: 200px;
        object-fit: contain;
        display: block;
        margin: auto;
}
.content_effact h2.title{
    font-weight: 500;
    font-size: 25px;
    text-transform: uppercase;
    color: #202020;
    text-align: left;
    margin-top: 31px;
    width: 191px;
    padding-top: 0px;
    border-top: 4px solid black;
    margin-left: 122px;
}
.content_effact p{
    font-size: 16px;
    color: #2D2D2D;
    margin-top:5px;
    padding-left: 122px;
}
.most_loved_line {
    background-color: black;
    height: 3px;
    width: 0%;
    position: absolute;
    left: 37%;
}
.most_love_heading{
    position: relative;
}
.most_love_heading:hover  .most_loved_line{
    animation: animateloveline 1.5s linear
}
@keyframes animateloveline {
    from{
        width:0%;
    }
    to{
        width: 27%;
    }
}
/* our client */
.clients_section_conatier {
    background: #222222;
}

.clients_section {
    padding: 170px 10px 121px;
}

.clients_section h2 {
    font-weight: 500;
    font-size: 48px;
    line-height: 55px;
    color: #FFFFFF;
    /* padding-left: 28px; */
}
.clients_line ,.about_line , .our_service_line , .bestsell_line{ 
    height: 3px;
    width: 0%;
    background-color: white;
}
.about_line , .our_service_line , .bestsell_line{
    background-color: black;
}
.bestline_text:hover .bestsell_line{
    animation: bestselllineanimation 1.5s linear
}
.about_heading:hover .about_line{
    animation: aboutlineanimation 1.5s linear
}
.service_heading:hover .our_service_line{
    animation: servicelineanimation 1.5s linear
}
.clients_section_heading:hover + .clients_line{
    animation: clientlineanimation 1.5s linear
}
@keyframes bestselllineanimation {
    from{
        width:0%
    }
    to{
        width:100%;
    }
}
@keyframes servicelineanimation {
    from{
        width: 0%;
    }
    to{
        width:80%
    }
}
@keyframes aboutlineanimation{
    from{
        width: 0%;
    }
    to{
        width:70%
    }
}
@keyframes clientlineanimation{
    from{
        width: 0%;
    }
    to{
        width:45%
    }
}
.clients_section h2 span {
    color: #FFE03F;
}

.clients_list {
    margin-top: 60px
}

.clients_list li {
    font-weight: 400;
    font-size: 20px;
    color: #FFFFFF;
    border-top: 1px solid #979797;
    padding: 16px 0;
    list-style: none;
}
.clients_list li:last-child{
    border-bottom: 1px solid #979797; 
}
.image_section{
    background-image: url("../../assets/images/imagesection.svg");
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
}
.body{
    cursor: crosshair;
}
.rounded{
    width: 26px;
    height: 26px;
    border: 2px solid #3d3b3b;
    border-radius: 50%!important;
    background-color: unset;
    position: fixed;
}
.zeroicon{
    position: relative;
}
.zeroicon img{
    display:none;
}
.zeroicon:hover img{
    display: block!important;
    position:absolute;
    top:0%;
    left:45%;
}
.oneicon{
    position: relative;
}
.oneicon img{
    display:none;
}
.oneicon:hover img{
    display: block!important;
    position:absolute;
    top:0%;
    left:45%;
}
.twoicon{
    position: relative;
}
.twoicon img{
    display:none;
}
.twoicon:hover img{
    display: block!important;
    position:absolute;
    top:0%;
    left:45%;
}
.threeicon{
    position: relative;
}
.threeicon img{
    display:none;
}
.threeicon:hover img{
    display: block!important;
    position:absolute;
    top:0%;
    left:45%;
}
.fouricon{
    position: relative;
}
.fouricon img{
    display:none;
}
.fouricon:hover img{
    display: block!important;
    position:absolute;
    top:0%;
    left:45%;
}
.fiveicon{
    position: relative;
}
.fiveicon img{
    display:none;
}
.fiveicon:hover img{
    display: block!important;
    position:absolute;
    top:0%;
    left:45%;
}
.sixicon{
    position: relative;
}
.sixicon img{
    display:none;
}
.sixicon:hover img{
    display: block!important;
    position:absolute;
    top:0%;
    left:45%;
}
.sevenicon{
    position: relative;
}
.sevenicon img{
    display:none;
}
.sevenicon:hover img{
    display: block!important;
    position:absolute;
    top:0%;
    left:45%;
}
.eighticon{
    position: relative;
}
.eighticon img{
    display:none;
}
.eighticon:hover img{
    display: block!important;
    position:absolute;
    top:0%;
    left:45%;
}
.nineicon{
    position: relative;
}
.nineicon img{
    display:none;
}
.nineicon:hover img{
    display: block!important;
    position:absolute;
    top:0%;
    left:45%;
}
@media screen and (max-width: 1024px) {
.cut_through_section h2 {
    font-size: 44px;
    line-height: 53px;
    margin-bottom: 20px;
}
}
@media screen and (max-width: 996px) {
    .marketing_page h2 {
        font-size: 50px;
        line-height: 50px;
    }
    .content_effact p{
        padding-left: 54px;
    }
    .content_effact h2.title{
        margin-left: 54px;
    }
    .cut_through_section{
        padding: 58px 5px;
    }
    .effactive_digital_section{
        padding: 58px 20px;
    }
    .OurServices_section .title_ecom  {
        font-size: 44px;
        line-height: 44px;
    }
    .sale_section {
        padding: 100px 20px;
    }
    .OurServices_section {
        background-color: #ECECEC;
        padding: 58px 20px;
    }
    /* .OurServices_section .accordion-item-start{
        margin-bottom: 0;
    } */
    .accordian_section p {
        margin-top: 10px;
    }
    .OurServices_section .accordion-button::after {
        position: absolute;
        top: 21px;
        right: 0;
    }
    .clients_section {
        padding: 58px 10px;
    }
    .clients_list{
        padding-left: 0px;
    }
    /* .marketing_page {
    height: 616px;
} */
}

@media screen and (max-width: 767px) {
    .marketing_page {
        height: 100%;
        padding: 100px 15px 0;
    }
    .marketing_page .explore_btn{
        display: block!important;
        margin: auto;
    }
    .effactive_digital_section h2{
        text-align: left;
    }
    .clients_section h2 {
        font-size: 33px;
        line-height: 40px;
        padding-left: 0;
    }
    .clients_list {
        padding: 0 16px 0 16px!important;
        margin-top:0;
        margin-bottom: 4px;
    }
    .effactive_digital_section span{
        font-size: 44px;
    }
    .clients_list li {
        font-size: 16px;
        padding: 13px 0;
        border-bottom: 1px solid #979797;
        border-top: none;
    }
    .sale_section h2 {
        font-size: 32px;
    }
    .sale_section p {
        font-size: 23px;
        line-height: 35px;
    }
    .content_effact {
        padding: 58px 0px 0px;
    }
    .OurServices_section .title_ecom {
        font-size: 70px;
        line-height: 66px;
    }
    .content_effact img {
        height: 150px;
        width: 150px;
        object-fit: contain;
        display: flex;
        margin: unset
    }
    .cut_through_section h2 {
        font-size: 62px;
        line-height: 66px;
        padding: inherit;
    }
    .cut_through_section p {
        font-weight: 300;
        font-size: 23px;
        letter-spacing: -0.30px;
        color: #2D2D2D;
        line-height: 37px;
        text-align: center;
    }
    .image_section {
        height: 55vh;
    }
    .content_effact p{
        padding-left: 0px;
    }
    .content_effact h2.title{
        margin-left: 0px;
    }
}

@media screen and (max-width: 556px) {
    .marketing_page h2 {
        font-size: 74px;
        line-height: 73px;
    }
    .most_loved_line{
        display: none;
    }
    .clients_section_conatier{
        padding: 0 9px;
    }
}