@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  overflow-x: hidden;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.accordion-item{
    border-radius: 0;
    background: unset;
    border: 0;
}
.accordion-button{
  padding: 0;
  border-radius: 0;
  color:white;
}
.accordion-button span{
  font-size: 14px;
}
.accordion-button p{
  font-size: 18px;
  font-family: Libre Baskerville;
}
.accordion-button:not(.collapsed){
  color:white;
  box-shadow: unset;
}
button:focus:not(:focus-visible) {
  outline: none!important;
}
button:focus{
  outline:none;
  box-shadow: unset!important;
  border-color: unset;
}
.accordion-button::after{
  background-image: url("./assets/images/plus.png");
  position: absolute;
    right: 1%;
}
.accordion-button:not(.collapsed)::after{
  background-image: url("./assets/images/minus.png");
  position: absolute;
    right: 1%;
}


@media screen and (max-width: 996px) {
.accordion-button{
  display: flex;
  flex-wrap: wrap;
}
.accordion-button::after {
  top: 2%;
}
.accordion-button:not(.collapsed)::after{
  top:2%
}
}