.career_page_section{
    background: #ECECEC;
    height: 100vh;
    padding: 210px 0 210px;
}
.career_page_section .header-heading-link{
    color:black
}
.body{
    cursor: crosshair;
  }
.rounded{
width: 26px;
height: 26px;
border: 2px solid #3d3b3b;
border-radius: 50%!important;
background-color: unset;
position: fixed;
}
.career_heading{
    font-weight: 500;
    font-size: 70px;
    line-height: 70px;
    margin-top: 23px;
    text-transform: uppercase;
    color: #222222;
}
.socila_meadia_heading{
    color:#2D2D2D;
    font-weight: 400;
    font-size: 24px;
    margin-top: 17px;
}
span.icon_social_link{
    height: 32px;
    width: 32px;
    border-radius: 32px;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
}
.form_container input{
    width: 100%;
    font-weight: 400;
    font-size: 16px;
    color: #2d2d2d;
    border: 0;
    outline: 0;
    background: no-repeat;
    border-bottom: 2px solid;
    margin-bottom: 20px;
}
input::placeholder{
    font-weight: 400;
    font-size: 16px;
    color: #2d2d2d;
}
.border_line{
    width: 100%;
    height: 2px;
    background: black;
    margin-top: 128px;
}
.msg_container{
    margin-top:20px;
}
.msg_container button{
    font-weight: 500;
    font-size: 19px;
    color:#202020;
}
.card_heading_line{
    width:0%;
    background-color: black;
    height:3px;
  }
  .career_heading:hover .card_heading_line{
    animation: careerline 1.5s linear;
  }
  @keyframes careerline {
  from{
     width:0%;
  }
  to{
     width:100%
  }
  }
@media screen and (max-width: 996px){
.career_heading{
    font-size: 44px;
    line-height: 52px;
}
.career_page_section{
    padding: 50px 0;
    height: 100vh;
}
.form_container input{
    margin-top: 27px;
}
}