.ecommerce_section {
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  min-height: 100vh;
  background-position: center center;
}
.ecom_wrapper {
  width: 100%;
  overflow-x: hidden;
}
.algo_container {
  padding: 50px 20px 40px;
  background: #d9d9d9;
  position: absolute;
  top: 23%;
  left: 11%;
  width: calc(100% - 40px);
}
.algo_container h2 {
  font-weight: 400;
  font-size: 25px;
  line-height: 37px;
  text-transform: uppercase;
  color: #202020;
  font-family: "tabloid";
  text-align: center;
}
.algo_img {
  height: 100vh;
  width: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  padding: 10px 8px 0;
  position: relative;
}
.algo_container label,
.algo_container span.break_content {
  font-family: "tabloid";
  font-weight: 400;
  font-size: 14px;
  text-transform: uppercase;
  color: #202020;
  margin: 29px 0 8px;
  width: 100%;
  display: block;
  line-height: 37px;
}
.algo_container span.break_content {
  margin: 5px 0 8px;
}
.algo_container span {
  font-weight: 400;
  font-size: 18px;
  color: #202020;
  font-family: "EB Garamond";
  display: block;
}
.my-services {
  border-top: 1px solid #202020;
}
.content {
  padding-top: 100px;
}
.algo_section h2.title_ecom {
  font-weight: 500;
  font-size: 48px;
  line-height: 55px;
  color: white;
}
.title-content {
  font-weight: 500;
  font-size: 25px;
  line-height: 37px;
  color: #202020;
}
.text-content {
  font-size: 18px;
  color: #202020;
  font-weight: 400;
}
.content h2 {
  font-weight: 500;
  font-size: 97px;
  line-height: 89px;
  text-transform: uppercase;
  color: #ffffff;
  letter-spacing: 0;
  margin-bottom: 30px;
}

.content p {
  font-weight: 400;
  font-size: 20px;
  color: #eeeeee;
  line-height: 26px;
}

.explore_btn {
  color: #ffe03f;
  background: transparent;
  border: none;
  margin-top: 29px;
}
.explore_btn img {
  height: 92px;
  width: 92px;
  object-fit: contain;
}
.title_linr,
.services_line {
  width: 0%;
  background-color: black;
  height: 3px;
}
.services_line {
  position: absolute;
  bottom: -28%;
  left: 0%;
}
.peope_line {
  height: 2px;
  width: 100%;
  background-color: black;
  margin: 0 0px;
}
.countdown_section:hover .peope_line {
  animation: peoplelineanimation 1.5s linear;
}
.clients_line {
  height: 3px;
  width: 0%;
  background-color: white;
  margin: 0 10px;
}
.clients_section_heading:hover + .clients_line {
  animation: clientlineanimation 1.5s linear;
}
@keyframes clientlineanimation {
  from {
    width: 0%;
  }
  to {
    width: 45%;
  }
}
@keyframes peoplelineanimation {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}
.title_heading:hover + .title_linr {
  animation: titleline 1.5s linear;
}
.service_heading:hover .services_line {
  animation: serviceline 1.5s linear;
}
.service_heading {
  position: relative;
}
@keyframes titleline {
  from {
    width: 0%;
  }
  to {
    width: 63%;
  }
}
@keyframes serviceline {
  from {
    width: 0%;
  }
  to {
    width: 42%;
  }
}
/* our service */
.our_service {
  padding: 170px 9px 80px;
}

.our_service h2 {
  color: #202020;
  font-weight: 500;
  font-size: 70px;
  line-height: 45px;
  text-align: left;
  margin-bottom: 93px;
}
.body {
  cursor: crosshair;
}
.rounded {
  width: 26px;
  height: 26px;
  border: 2px solid #3d3b3b;
  border-radius: 50% !important;
  background-color: unset;
  position: fixed;
}

.service_list li {
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  color: #222222;
  margin-bottom: 12px;
  list-style-type: disc;
}
ul.nav-tabs {
  color: white;
  font-weight: 700;
  font-size: 14px;
  margin-top: 60px;
  border-bottom: none;
  padding-left: 0;
  justify-content: flex-start;
  z-index: 0 !important;
  padding: 0 5px;
}
ul.nav-tabs button {
  background: transparent !important;
  color: white !important;
  border: 0 !important;
  padding: 0;
  margin: 0 11px;
  font-size: 14px;
  text-transform: uppercase;
}
.service_list li b {
  font-weight: 600;
}

.ecom_consultant_section {
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  min-height: 100vh;
  background-position: center center;
  padding: 170px 72px;
}

.title_ecom {
  font-weight: 500;
  font-size: 70px;
  line-height: 70px;
  text-transform: uppercase;
  color: #222222;
}

.title_ecom span {
  color: #ffe03f;
}

.ecom_consultant_section p {
  font-weight: 400;
  font-size: 20px;
  letter-spacing: -0.36px;
  color: #222222;
  margin-top: 45px;
  line-height: 33px;
}

.modus_conatiner {
  padding: 150px 77px 150px;
}

.modus_conatiner h2 {
  font-weight: 500;
  font-size: 48px;
  line-height: 55px;
  color: #222222;
  text-align: center;
  margin-bottom: 93px;
}

.modus_conatiner h2 span {
  color: #ffe03f;
}

.modus_conatiner p {
  font-weight: 400;
  font-size: 23px;
  line-height: 35px;
  letter-spacing: -0.36px;
  color: #2d2d2d;
}

.operandi_list {
  margin-top: 30px;
}

.operandi_list li {
  font-weight: 400;
  font-size: 23px;
  letter-spacing: -0.36px;
  color: #2d2d2d;
  margin-bottom: 20px;
}

.modus_section {
  background: #f3f3f3;
}

.clients_section_conatier {
  background: #222222;
}

.clients_section {
  padding: 170px 10px 121px;
}

.clients_section h2 {
  font-weight: 500;
  font-size: 48px;
  line-height: 55px;
  color: #ffffff;
  padding: 0 5px;
  /* padding-left: 28px; */
}

.clients_section h2 span {
  color: #ffe03f;
}

.clients_list {
  margin-top: 60px;
}

.clients_list li {
  font-weight: 400;
  font-size: 20px;
  color: #ffffff;
  border-top: 1px solid #979797;
  padding: 16px 0;
  list-style: none;
}

.zeroicon{
    position: relative;
}
.zeroicon img{
    display:none;
}
.zeroicon:hover img{
    display: block!important;
    position:absolute;
    top:0%;
    left:45%;
}
.oneicon{
    position: relative;
}
.oneicon img{
    display:none;
}
.oneicon:hover img{
    display: block!important;
    position:absolute;
    top:0%;
    left:45%;
}
.twoicon{
    position: relative;
}
.twoicon img{
    display:none;
}
.twoicon:hover img{
    display: block!important;
    position:absolute;
    top:0%;
    left:45%;
}
.threeicon{
    position: relative;
}
.threeicon img{
    display:none;
}
.threeicon:hover img{
    display: block!important;
    position:absolute;
    top:0%;
    left:45%;
}
.fouricon{
    position: relative;
}
.fouricon img{
    display:none;
}
.fouricon:hover img{
    display: block!important;
    position:absolute;
    top:0%;
    left:45%;
}
.fiveicon{
    position: relative;
}
.fiveicon img{
    display:none;
}
.fiveicon:hover img{
    display: block!important;
    position:absolute;
    top:0%;
    left:45%;
}
.sixicon{
    position: relative;
}
.sixicon img{
    display:none;
}
.sixicon:hover img{
    display: block!important;
    position:absolute;
    top:0%;
    left:45%;
}
.sevenicon{
    position: relative;
}
.sevenicon img{
    display:none;
}
.sevenicon:hover img{
    display: block!important;
    position:absolute;
    top:0%;
    left:45%;
}
.eighticon{
    position: relative;
}
.eighticon img{
    display:none;
}
.eighticon:hover img{
    display: block!important;
    position:absolute;
    top:0%;
    left:45%;
}
.nineicon{
    position: relative;
}
.nineicon img{
    display:none;
}
.nineicon:hover img{
    display: block!important;
    position:absolute;
    top:0%;
    left:45%;
}

.clients_list li:last-child{
    border-bottom: 1px solid #979797; 
}
.countdown_section {
  padding: 170px 0;
}
.people_count {
  padding: 20px 0;
  /* border-top:1px solid #202020; */
}
.people_count span {
  font-size: 14px;
  color: #2d2d2d;
}
.people_count h2 {
  color: #202020;
  font-size: 90px;
  font-weight: 800;
  font-family: "Anton";
}
.people_count p {
  font-size: 20px;
  color: #555a6c;
}
.nav {
  position: static;
}
@media screen and (max-width: 996px) {
  .content h2 {
    font-size: 50px;
    line-height: 50px;
  }
  .clients_section {
    padding: 58px 10px;
  }
  .clients_list {
    padding-left: 0px;
  }
  .our_service {
    padding: 58px 20px;
  }
  .our_service h2 {
    font-size: 44px;
    margin-bottom: 45px;
  }
  .ecom_consultant_section {
    padding: 58px 20px;
  }
  .title_ecom {
    font-size: 44px;
    line-height: 44px;
  }
  .ecom_consultant_section p {
    margin-top: 25px;
  }
  .modus_conatiner {
    padding: 58px 20px;
  }
  .modus_conatiner h2 {
    margin-bottom: 45px;
  }
  .countdown_section {
    padding: 58px 20px;
  }
}

@media screen and (max-width: 767px) {
  .clients_section h2 {
    font-size: 33px;
    line-height: 40px;
    padding-left: 0;
  }
  ul.nav-tabs button {
    padding: 0 0px 0 0 !important;
  }
  .algo_section h2.title_ecom {
    font-size: 70px;
    line-height: 69px;
  }
  .people_count p {
    font-size: 16px;
  }
  .people_count h2 {
    font-size: 99px;
    margin: 15px 0;
  }
  .clients_list {
    padding: 0 16px 0 16px!important;
    margin-top: 0;
    margin-bottom: 4px;
  }
  .clients_list li {
    font-size: 16px;
    padding: 13px 0;
    border-bottom: 1px solid #979797;
    border-top: none;
  }
  .our_service h2 {
    font-size: 38px;
    margin-bottom: 38px;
  }
  .service_list li {
    font-size: 16px;
    line-height: 25px;
    margin-bottom: 10px;
  }
  .title_ecom {
    font-size: 38px;
    line-height: 38px;
  }
  .ecom_consultant_section p {
    font-size: 20px;
    line-height: 30px;
  }
  .modus_conatiner h2 {
    margin-bottom: 32px;
    font-size: 33px;
  }
  .modus_conatiner p {
    font-weight: 400;
    font-size: 17px;
    line-height: 26px;
  }
  .operandi_list li {
    font-size: 17px;
    margin-bottom: 14px;
  }
  .our_service {
    padding: 58px 30px;
  }
  ul.nav-tabs{
      padding: 0 3px;
  }
  .tab-content {
    padding: 0;
}
}
@media screen and (max-width: 556px) {
  .content h2 {
    font-size: 70px;
    line-height: 64px;
  }
  .clients_line{
      margin:0
  }
  .clients_section_conatier{
      padding: 0 9px;
  }
  .ecom_consultant_section {
    padding: 130px 20px 196px;
    align-items: baseline !important;
    min-height: 100vh;
  }
  .algo_container label, .algo_container span.break_content {
    font-size: 11px;
    margin: 21px 0 8px;
}
.algo_container span{
    font-size: 13px;
}
.algo_container span.break_content {
    margin: 0px;
}
  .content {
    padding: 152px 29px 0;
  }
  .algo_container h2{
      font-size: 18px;
  }
  .title_ecom {
    font-size: 70px;
    line-height: 70px;
  }
  .algo_container {
    top: 43%;
    left: 23%;
    padding: 48px 10px 25px;
    width: calc(80% - 20px);
}
  .algo_img {
    margin-top: 80px;
    height: 76vh;
  }
}
@media screen and (max-width: 510px) {
  .algo_container {
        top: 30%;
        left: 10%;
        width:calc(100% - 20px);
  }
  .algo_img {
    height: 50vh;
  }
}
