/* @import 'bootstrap/dist/css/bootstrap.min.css'; */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
@tailwind base;
/* @tailwind components; */
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Anton&family=EB+Garamond:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700;1,800&family=Libre+Baskerville:ital,wght@0,700;1,400&family=Manrope:wght@200;300;400;500;600;800&family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');

.tabloid{
  font-family: "tabloid" !important;
}

/* use font-["Anton"] for using font  Anton , name for differnt fonts are below */
/* font-family: 'Anton', sans-serif;
font-family: 'EB Garamond', serif;
font-family: 'Libre Baskerville', serif;
font-family: 'Manrope', sans-serif;
font-family: 'Roboto Condensed', sans-serif; */

/* for using tabloid directely add tabloid in class */
@font-face {
  font-family: "tabloid";   /*Can be any text*/
  src: local("Tabloid Scuzzball"),
    url("./font/TabloidScuzzball.otf") format("opentype");
}
 

.App {
    text-align: center;
    font-family: 'Poppins', sans-serif;
  }
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  .img_container{
    height: 32px;
    width: 32px;
    border-radius: 32px;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
}
  .App-link {
    color: #61dafb;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
