.header_underline{
    width: 143px;
    height: 4px;
    background-color: black;
    margin-top:33px;
    /* animation: fullLine 2s linear; */   
}
.brand_icon{
    height:100px;
    width:100px;
    object-fit: contain;
}
.load_more_btn{
    font-weight: 400;
    font-size: 19px;
    color: #202020;
    font-family: tabloid;
}
.industry_content  img{
  height:200px;
  width:200px;
  object-fit: contain;
}
.projects_img_content{
    height:300px;
    width:300px;
    margin-left:46px;
    margin-right: auto;
}
.projects_img_content:nth-child(3){
    margin-right: unset;
}
.projects_img_content:nth-child(6){
    margin-right: unset;
}
.projects_img_content:nth-child(9){
    margin-right: unset;
}
.project_col{
    padding: 0 57px;
}
.headerwidth{
    width: 74px;
    height: 2px;
    background-color: black;
}
.projects_img_content :hover + .header_underline{
    width: 143px;
    animation: fullLine 1.5s linear;
}
.projects_img_content :hover ~ .headerwidth{
    width: 75px;
    animation: contentLine 1.5s linear;
}
.project_line{
    width:0%;
    background-color: black;
    height:3px;
  }
  .project_title:hover + .project_line{
    animation: linewidth 1.5s linear;
  }
  @keyframes linewidth {
  from{
     width:0%;
  }
  to{
     width:43%
  }
  }
@keyframes fullLine {
    from{
        width: 0px;
    }
    to{
        width:74px
    }
}
@keyframes contentLine {
    from{
        width: 0px;
    }
    to{
        width:74px;
    }
}

@media screen and (max-width: 556px) {
.project_col {
    padding: 0 10px;
}
.industry_content img {
    height: 451px;
    width: 368px;
    object-fit: contain;
}
.project_values {
    padding: 0 41px;
}
.projects_img_content{
    height: unset;
    width: unset;
    margin-left: unset;
    margin-right: unset;
}
.brand_icon{
    height: 450px;
    width: 368px;
}
.contry_icon{
    height:345px;
    width: 368px;
}
}